.error { color: #ed0425; padding-bottom:10px;text-align: left; font-size: 14px; }
a, a:hover{
    text-decoration: none;
}

.multi-action {
	position: relative;
}
.action-button {
	width: 46px;
	height: 46px;
	margin: auto;
	border-radius: 50%;
	border: 0;
	outline: 0;
	background: #fff;
	font-size: 24px;
	border: 1px solid #eee;
	z-index: 2;
	color: #555;
	transition: all 0.3s;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.action-button:hover{
	background-color: #eee;
}
.actions {
	position: absolute;
	list-style: none;
	width: auto;
	top: 50%;
	right: 100%;
	z-index: 1;
	display: flex;
	transform: translateY(-50%);
	opacity: 0;
	visibility: hidden;
}
.actions .btn {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px;
	background-color: #555;
	transition: 0.4s all;
	transform:scale(0.3);
	margin-right: 6px;
	box-shadow: 0px 4px 4px #0000001A;
}
.action-button.active ~ .actions{
	opacity: 1;
	visibility: visible;
}
.actions .btn.bg-teal{
	background-color: #3f51b5;
}
.actions .btn.bg-red {
	background-color: #ff1e1e;
}
.actions .btn:last-child{
	margin-right: 0;
}
.action-button.active ~ .actions .btn {
	transition: all 0.3s;
	transform: scale(1);
}
.multi-action .actions .action-tags {
	padding: 4px;
	text-align: center;
	justify-content: center;
}
.multi-action .actions .action-tags image{
    color: #fff !important;
}
.multi-action{
	width: 56px;
	padding: 0;
}
.cursor-pointer{
	cursor: pointer;
}
.min-height-72vh{
    min-height: 72vh !important;
}

.custom-form-group .custom-react-select input {
    height: 55px;
    color: var(--bs-body-color);
}

.custom-form-group .custom-react-select>div>div>div:first-child {
    color: var(--bs-body-color);
    opacity: 0.8;
}

.custom-form-group .custom-react-select>span+div {
    box-shadow: none !important;
    border-radius: 12px;
}
.custom-form-group .custom-react-select>span+div+div{
    z-index: 3;
}
.custom-form-group .custom-react-select>span+div>div {
    overflow: auto;
    max-height: 100px;
}

/* .infinite-scroll-component, .infinite-section .infinite-scroll-component{
    height: inherit !important;
    overflow: hidden !important;
} */

.zIndex-100{
	z-index: 100;
}

.width-height-initial{
	width: initial !important;
	height: initial !important;
}
.calendarScrollableDiv{
	height: 150px;
	overflow: auto;
}
.nl2br {
	white-space: pre-wrap;
}

#notesScrollableDiv{
	height: calc(100vh - 5.5rem - 261px) !important;
	overflow: auto;
}
.pointer-events-none{
	pointer-events: none !important;
}

.custom-table tbody, td, tfoot, th, thead, tr {
    border-style: none;
}

#jobNotesScrollableDiv{
	height: 261px !important;
	overflow: auto;
}
.width-initial{
	width: initial !important;
}
.h-500{
	height: 500px !important;
}
.color-white{
	color: #fff !important;
}
.m-l-5{
	margin-left: 5px !important;
}
.m-l-10{
	margin-left: 10px !important;
}
.text-decoration-none, .text-decoration-none:hover{
    text-decoration: none !important;
}

.nl2br {
	white-space: pre-wrap;
}
/* New css */
.advanced-btn.reports_advanced_filter button.btn{
    min-width: 120px;
    height: 68px;
    margin-top: 10px;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #0662B9;
    border: 0;
    border-bottom: 1px solid #0662B9;
    border-radius: 0;
    height: auto;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin: 0 auto;
    outline: none;
}
.advanced-btn.reports_advanced_filter button.btn:active {

    border-bottom: 1px solid #0662B9;
    outline: none;
}
.advanced-btn.reports_advanced_filter button.btn img {
    margin-left: 10px;
}
.event-container .round-all.event-item .row.crew-members>[class*=col] div.terminate-emp{
    position: relative;
    background-color: #fff!important;
}
.event-container .round-all.event-item .row.crew-members>[class*=col] div.terminate-emp::after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    content: "";
}

.event-container .round-all.event-item .row.assets>[class*=col] div.terminate-emp{
    position: relative;
    background-color: #fff!important;
}
.event-container .round-all.event-item .row.assets>[class*=col] div.terminate-emp::after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    content: "";
}

.attachment--boxe{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    position: relative;
    flex-direction: column;
}
.attachment--boxe:last-child{
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.attachment--boxes{
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 15px;
}
.attachment--boxe>div{
    flex: 0 0 auto;
}
.attachment--boxe .images-column{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.attachment--boxe .images-column .image--box{
    width: 10%;
    flex: 0 0 auto;
    padding: 5px;
    flex: 0 0 auto;
    display: flex;
}
.attachment--boxe .images-column .image--box figure img{
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}
.attachment--boxe .images-column .image--box.document--box .image--box_iner{
    background-color: #eeeeee;
}
.attachment--boxe .images-column .image--box.document--box figure img{
    opacity: 0.5;
    width: 40px;
    height: 40px;
    aspect-ratio: inherit;
    object-fit: contain;
}
.attachment--boxe .images-column .image--box_iner{
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    flex: 1;
    display: flex;
}
.attachment--boxe .images-column .image--box_iner figure{
    margin: 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
}
.attachment--boxe .images-column .image--box_iner .box-remove-btn{
    position: absolute;
    padding: 0;
    border: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    right: 5px;
}
.attachment--boxe .images-column .image--box_iner .box-remove-btn img{
    width: 10px;
}
.attachment--boxe .description-column{
    width: 100%;
    padding-top: 15px;
    align-self: center;
}
.attachmentActive-btn{
    position: absolute;
    right: -8px;
    top: -10px;
    display: flex;
}
.attachmentActive-btn span.action-btn {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0662b9;
    padding: 5px;
    background-color: #0662b9;
    cursor: pointer;
}
.attachmentActive-btn span.action-btn.edit-note{
    margin-left: 5px;
}
.attachmentActive-btn span.action-btn img{
    filter: invert(1) brightness(5);
}
.notes-list .notes-footer span{
	width: auto;
}
.timeline-event {
	overflow: hidden;
}
.timeline-event .job-edit-btns{
	padding-left: 0;
}
.timeline-event .groupView{
	position: absolute;
    left: -30px;
    height: 70px;
    display: block;
    width: 130%;
    top: -49px;
    transform: rotate(-10deg);
}
/* .timeline-event .groupView{
	position: absolute;
    left: 0;
    height: calc(100% - 40px);
    display: block;
    width: 20px;
    top: 20px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 0;
} */
 .attachmentBoxes-wrap .card{
	box-shadow: none;
	border: 0;
 }
.attachmentBoxes-wrap .card-body{
	margin: 0;
}
.attachmentBoxes-wrap .attachmentBoxes-btn{
	padding: 0;
	margin: 0 0 0 0;
	padding: 10px 35px 10px 20px;
    width: 100%;
    text-align: left;
    border-radius: 5px;
	color: #000;
	font-weight: 600;
	position: relative;
}
.attachmentBoxes-wrap .attachmentBoxes-btn::after{
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	content: "";
	background-image: url(../images/add_plus_icon.svg);
	width: 15px;
    height: 15px;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
}
.attachmentBoxes-wrap .attachmentBoxes-btn[aria-expanded="true"]::after{
	background-image: url(../images/minus_icon.svg);
}
.defaultMenu-open .menu-btn{
    display: none;
}
.attachmentBoxes--top{
    display: flex;
    margin-bottom: 15px;

}
.attachmentBoxes-download.btn.btn-fill{
    padding: 10px;
    height: auto;
    border-radius: 4px;
    margin-left: 15px;
}
.attachmentBoxes-download.btn.btn-fill img{
    height: 21px;
    filter: brightness(0) invert(1);
}
@media(max-width:991px){
    .attachment--boxe .images-column .image--box{
        width: 12.5%;
    }
}
@media(max-width:767px){
    .attachment--boxe .images-column .image--box{
        width: 16.666%;
    }
}
@media(max-width:576px){
    .attachment--boxe .images-column .image--box{
        width: 20%;
    }
	.notes-footerBottom{
		width: 100%;
	}
}
@media(max-width:480px){
    .attachment--boxe .images-column .image--box.document--box figure img{
        width: 30px;
        height: 30px;
    }
    .attachment--boxe .images-column .image--box{
        width: 25%;
    }
    .attachment--boxe .images-column .image--box_iner .box-remove-btn{
        width: 20px;
        height: 20px;
    }
}
@media(max-width:375px){
    .attachment--boxe .images-column .image--box{
        width: 33.33%;
    }
}


.details-listInner {
    position: relative;
    padding: 0 0 15px 35px;
    border-bottom: solid 1px #e9e9e9;
}
.details-listInner figure {
    position: absolute;
    left: 0;
    top: 0px;
    opacity: 0.8;
    margin: 0;
}
.details-listInner figure img{
    width: 24px;
    height: 24px;
}
.details-listInner span {
    font-size: 14px;
    line-height: 15px;
    font-size: 18px;
    font-weight: 600;
}
.details-listInner .skill-set span {
    margin: 5px 5px 2.5px 0;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
}
.mapModal .btn-close {
    padding: 0;
    position: absolute;
    right: 28px;
    top: 22px;
    height: auto;
    width: auto;
    background: none;
    text-align: right;
    color: #000000;
    font-size: 14px;
    opacity: 1;
    z-index: 1;
}
.mapModal h3 {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 0;
    color: #000;
}
.mapModal .modal-header{
    border: 0;
    padding: 20px 20px 20px 20px;
}
.mapModal .modal-body{
    padding: 0 20px 20px;
}
.mapModal-job-list>span{
    display: block;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px #e9e9e9;
}
.mapModal-job-list>span:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.strikethrough {
    text-decoration: line-through;
    color: #D3D3D3;
}

.kebab-edit-btn{
    border: none;
    padding: 0;
    background-color: transparent;
    outline: none;
    transition: all 0.3s ease-in-out;
}
.timeline-event.night-shift .kebab-edit-btn img{
    filter: invert(1) brightness(5);
}
.job-btns-box{
    position: absolute;
    left: 28px;
    top: 0;
    z-index: 9;
    /* transform: scale(0); */
    width: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}
.job-btns-box.show{
    width: auto;
    opacity: 1;
    visibility: visible;
    transform: none;
    transition: all 0.3s ease-in-out;

}

/* .job-edit-btns:has(.kebab-edit-btn:hover) .job-btns-box{
    width: 80px;
    opacity: 1;
    visibility: visible;
    transform: none;

} */
.timeline-event {
    padding: 14px 0 10px !important;
}
.round-all.event-item>.row:not(:last-child){
  margin-bottom: 0  !important;
}
.event-container .round-all.event-item .text-primary{
    padding: 0 50px !important;
}


.import-data-label a{
    position: relative !important;

}
.equipment-add-btn{
    padding-right: 10px;
    display: block;
    margin-right: 10px;
    border-right: 2px solid #b7b7b7;
}
.equipment-add-btn img{
    width: 20px;
}
.view-modal-details {
    color: #858585;
}

.crop-popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
    z-index: 9;
}


.small-graph-box .small-graph{
    height: auto;
    width: 80px;
    height: 80px;
}
.small-graph-box .small-graph figure{
    margin-bottom: 0;
}
.small-graph-box .small-graph figure img{
    width: 100%;
}
.small-graph-content span{
    font-size: 15px;
}
.form-group.checkbox-row{
    margin-left: 0;
    margin-right: 0;
}
.table-responsive .user-type-link , .table-responsive .user-type{
    color: #000;
    text-transform: capitalize;
}
.foremen-history-box .accordion-header .accordion-button  span img{
    width: 20px;
    filter: invert(1) brightness(5);
}
.foremen-history-box .accordion-header .accordion-button.collapsed  span img{
    width: 20px;
    filter: invert(1) brightness(0);
}
.checkbox-row.employee-checkbox-row{
    margin: 0 -15px;
}


.visibility-mode input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.visibility-mode label {
	cursor: pointer;
	text-indent: -9999px;
	width: 55px;
	height: 29px;
	background: grey;
	display: block;
	border-radius: 30px;
	position: relative;
}

.visibility-mode label:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 4px;
	width: 21px;
	height: 21px;
	background: #fff;
	border-radius: 50%;
	transition: 0.3s;
}
.visibility-mode input:checked + label {
	background: #0662B9;
}

.visibility-mode input:checked + label:after {
	left: calc(100% - 4px);
	transform: translateX(-100%);
}

.visibility-mode label:active:after {
	width: 30px;
}
.header-profile-inner .dropdown-menu li:has(.visibility-mode){
    padding: 2px 16px;
}
.header-profile-inner .dropdown-menu li:has(.visibility-mode) span{
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.header-logo img.logo-white{
    display: none ;
}

/* body:has(.visibility-mode input:checked){
    background-color: #000000;
} */


.customSelect-wrap .custom-react-select>span+div {
    box-shadow: none !important;
    border-radius: 12px;
    background-color: #F8F8F8;
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: var(--bs-body-color);
    height: 70px;
    min-height: auto;
}
.customSelect-wrap .custom-react-select>span+div>div {
    overflow: auto;
    max-height: 65px;
    font-size: 16px;
    color: #84818A;
    padding: 2px .75rem;
}
.customSelect-wrap .custom-react-select>div>div>div:first-child {
    color: var(--bs-body-color);
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.customSelect-wrap .custom-react-select input {
    height: 44px;
    color: var(--bs-body-color);
}
.customSelect-wrap .custom-react-select label{
    padding: 0;
}

.resource-force-confirm{
    font-size: 14px;
    color: red;
    display: block;
}
.resource-force-confirm strong{
    font-size: 16px;
    color: #000;
}
.table-responsive .user-type-link{
    color: #0662b9 !important;
}
.details-listing {
    display: flex;
    flex-wrap: wrap;
}
.details-listing li{
    width: 33.33%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 11px;
    margin-bottom: 11px;
}
.details-listing li.image-box{
    width: 100%;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 15px;
    background-color: rgb(6, 98, 185,0.05);
}
.details-listing li.image-box .inner-wrap{
    padding-right: 0 !important;
}
.details-listing li.image-box .inner-wrap span{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.details-listing li .inner-wrap{
    height: 100%;
    padding-right: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.details-listing li .inner-wrap p{
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 2px;
    color: rgb(33 37 41 / 75%);
}
.details-listing li .inner-wrap span{
    font-size: 15px;
    font-weight: 500;
    color: #2a2a2a;
    text-transform: capitalize;
}
.details-listing li .inner-wrap a{
    font-size: 15px;
    font-weight: 500;
    color: #0662B9;
    padding-left: 5px;
    text-transform: capitalize;
}
.profile-detail-box{
    display: flex;
    align-items: center;
    /* margin-bottom: 30px; */

}
.profile-detail-box div>div{
    padding-left: 15px;
}
.profile-detail-box div.inner-wrap p {
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 2px;
    color: rgb(33 37 41 / 75%);
}
.profile-detail-box div.inner-wrap span{
    font-size: 15px;
    font-weight: 500;
    color: #2a2a2a;
    text-transform: capitalize;
}
.detail-modal-editbtns{
    position: absolute;
    top: 13px;
    right: 90px;
    display: flex;
    align-items: center;
}
.detail-modal-editbtns a:not(:last-child){
    margin-right: 15px;
}
.detail-modal-editbtns a{
    width: auto;
    height: 38px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    color: #606060;
}
.detail-modal-editbtns a:hover{
    color: #fff;
    background-color: #0662B9;
    border-color: #0662B9;
}
.detail-modal-editbtns a img{
    filter: invert(1) brightness(1.5);
    margin-right: 5px;
    transition: all 0.3s ease-in-out;

    width: 15px;
}
.detail-modal-editbtns a:hover img{
    filter: invert(1) brightness(5);
}
.add-employee .input-with-label{
    background-color: transparent;
}
#adduserModal .input-with-label{
    background-color: transparent;
}
.input-with-label:focus{
    outline: none;
    box-shadow: none;
    border-color: #ddd;
    background-color: transparent;
}
.filter-box .form-group .custom-react-select>span+div{
    height: 50px;
    background-color: transparent;

}
.input-with-label{
    height: 50px;
    background-color: transparent;
    font-size: 14px;
}
.input-with-label .rs-input-group input{
    font-size: 14px;

}
.filter-box .form-group .custom-react-select>span+div>div{
    max-height: 45px;
    font-size: 14px;
}
.adduser-form .form-floating .form-control{
    height: 50px;
}
.custom-form-group .custom-react-select>span+div>div{
    max-height: 50px;
}
.custom-form-group .custom-react-select input{
    height: 33px;
}
.filter-box .form-group .custom-react-select input{
    height: 33px;
}
.adduser-form .custom-upload label {
    height: 50px;
}
.adduser-form .custom-upload label span{
    height: 44px;
}
.calendar-search input, .calendar-search select{
    height: 50px !important;
}
.crew-modal .form-group .custom-react-select>span+div{
    height: 50px;
}
.crew-modal .form-group .custom-react-select>span+div.css-t3ipsp-control{
    border-color: #ddd;
}
.crew-modal .form-group .custom-react-select>span+div>div{
    height: 100%;
}
.crew-modal .form-group .custom-react-select input{
    height: 35px !important;
}
.crew-modal .form-group select, .crew-modal .form-group input.form-control{
    height: 50px !important;
    font-size: 14px;

}
.crew-modal .form-group textarea.form-control{
    font-size: 14px;
}
.custom-checkbox label{
    font-size: 14px;
}
.crew-modal .form-group .custom-react-select>div>div>div:first-child{
    font-size: 14px;
}
.detail-modal-editbtns a.details_delete_icon{
    background-color: #b00000;
    border-color: #b00000;
}
.modal-open .react-confirm-alert-overlay{
    z-index: 9999;
}
.filter-box .btn{
    font-size: 16px !important;
}
.form-check-group label{
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}
.custom-form-group .custom-react-select>span+.css-t3ipsp-control{
    border-color: #ddd !important;
}
.custom-form-group .custom-react-select>span+div:hover{
    border-color: #ddd !important;
}
.upload_source_box label{
    width: calc(100% - 60px - 10px);
    margin-right: 10px;
}
.download_file{
    background-color: #0662B9;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #0662B9;
    transition: all 0.2s ease-in-out;
}
.download_file:hover{
    background-color: #fff;
}
.download_file img{
    filter: invert(1) brightness(5);
    width: 30px;
    transition: all 0.2s ease-in-out;
}
.download_file:hover img{
    filter: none;
}
.employee-checkbox-row.mt-0{
    margin-top: 0 !important;
}
.profiledetail-head h3{
    margin-bottom: 0 !important;
}
.profiledetail-head{
    margin-bottom: 25px;
}
.profiledetail-head span{
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}
.termination-list{
    margin-top: 5px;
}
.termination-list li{
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    width: auto !important;
    line-height: 18px;
}
.termination-list li:not(:last-child){
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px solid #c9c9c9;
}
.details-listing .termination-list li span{
    display: block;
    color: #606060;
}
.employee-submit .left-box{
    margin-left: 0 !important;
}
.employee-submit .right-box{
    margin-left: 0 !important;
}
.employee-submit .right-box .details_delete_icon {
    border-color: #ddd;
    color: #606060;
}
.employee-submit .right-box .details_delete_icon {
    border-color: #b00000;
    background-color: #b00000;
    color: #fff;
}
.employee-submit .right-box .details_delete_icon img{
    filter: invert(1) brightness(5);
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
}
.custom-upload .recent-img-box{
    width: 40px;
    height: 40px;
    padding: 0;
    transform: none;
    bottom: 5px;
    top: auto;
}
.pagination li>*{
    width: auto;
    height: 40px;
    border-radius: 5px !important;
    padding: 5px 15px;
}
.form-group:has(.import-data-label) .custom-react-select>span+div {
    height: 100% !important;
    max-height: 100% !important;
}
.form-group:has(.import-data-label) .custom-react-select>span+div>div{
    height: auto !important;
    max-height: 100% !important;

}
@media (max-width: 1599px) {
    .dashboard-right {
        margin-left: 310px;
        width: calc(100% - 310px);
    }
}
@media (max-width: 1199px){
    .details-listing li{
        width: 50%;
    }
}
@media (max-width: 991px) {
    .dashboard-right {
        width: 100%;
        margin-left: 0px;
    }
    .defaultMenu-open .menu-btn{
        display: block;
    }
    .advanced-btn{
        margin-bottom: 30px;
    }
    .input-with-label{
        height: 40px;
        font-size: 14px;
    }
    .input-with-label .rs-input-group input{
        font-size: 14px;
    }
    .filter-box .form-group .custom-react-select>span+div {
        height: 40px;
        font-size: 14px;
    }
    .filter-box .form-group .custom-react-select>span+div>div{
        font-size: 14px;
    }
    .dashboard-heading {
        margin-bottom: 14px;
    }
    .btn.btn-filter[aria-expanded="true"]::after {
        height: 69px;
    }
    .filter-box .form-group .custom-react-select input {
        height: 27px;
    }
    .filter-box .form-group .custom-react-select>span+div>div {
        max-height: 39px;
    }
    .filter-box .form-group .custom-react-select>span+div>div:has(svg){
        max-height: 40px ;
    }

    .section-head .right-button{
        margin-bottom: 10px;
    }
    .filter-box .form-floating>.form-control, .filter-box .form-floating>.form-control-plaintext{
        height: 44px;
        min-height: 44px;
        font-size: 14px;
    }
    .adduser-form .form-floating label{
        font-size: 14px;
    }
    .adduser-form .form-floating>.form-control-plaintext~label, .adduser-form .form-floating>.form-control:focus~label, .adduser-form .form-floating>.form-control:not(:placeholder-shown)~label, .adduser-form .form-floating>.form-select~label {
        transform: scale(.85) translateY(-1.4rem) translateX(0.15rem);
    }
    .crew-modal .modal-dialog {
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }
    .custom-form-group .custom-react-select>span+div>div {
        max-height: 44px !important;
        overflow: auto !important;
    }
    .custom-form-group .custom-react-select>span+div {
        min-height: 44px;
    }
    .custom-form-group .custom-react-select>span+div>div>div label{
        font-size: 14px;
    }
    .custom-form-group .custom-react-select input {
        height: 32px;
    }
    .adduser-form .custom-upload label {
        height: 44px;
    }
    .adduser-form .custom-upload label span {
        height: 38px;
        font-size: 14px;
    }
    .adduser-form .custom-upload label {
        padding-right: 80px;
    }
    .custom-upload label p{
        font-size: 14px;
    }
    .download_file{
        width: 42px;
        height: 42px;
    }
    .download_file img {
        width: 23px;
    }
    .upload_source_box label{
        width: calc(100% - 42px - 10px);
    }
    .profiledetail-head{
        margin-top: 15px;
    }
    .crew-modal .form-group .custom-react-select>span+div{
        height: 40px;
    }
    .crew-modal .form-group .custom-react-select input {
        height: 26px !important;
    }
    .crew-modal .form-group select, .crew-modal .form-group input.form-control{
        height: 40px !important;
    }
    .custom-upload .recent-img-box{
        bottom: 2px;
    }

}
@media(max-width:767px){
    .mapModal .modal-body{
        padding: 35px 15px 15px 15px;
    }
    .mapModal h3{
        font-size: 20px;
    }
    .upload-img-popup{
        height: 100% !important;
    }
    .details-listing li{
        width: 100%;
    }
    .profile-detail-box .sort-name{
        width: 70px;
        height: 70px;
    }
    .details-listing li .inner-wrap p {
        font-size: 14px;
    }
    .details-listing li .inner-wrap span {
        font-size: 14px;
    }
    .details-listing li .inner-wrap a{
        font-size: 14px;
    }
    .btn.btn-filter[aria-expanded="true"]::after {
        height: 59px;
    }
    .employee-submit .right-box{
        width: 100%;
        margin-top: 20px;
    }
    .employee-submit .left-box{
        width: 100%;
    }
    .employee-submit .left-box button{
        width: 50% !important;
    }
    .employee-submit .left-box div{
        width: calc(50% - 15px) !important;
    }
    .employee-submit .left-box div a , .employee-submit .left-box div button{
        width: 100% !important;
    }
    .employee-submit .right-box .details_delete_icon{
        width: 100%;
    }
    .checkout-process-strip::before{
        transform: translateY(-50%);
        top: 50%;
    }
}
@media(max-width:576px){
    .sectoin-head .right-button .btn.btn-fill{
        margin-right: 0 !important;
    }
}

.text-transform-none{
    text-transform: inherit !important;
}

.react-confirm-alert-body-element .react-confirm-alert-overlay {
    z-index: 9999;
}

.add-employee .employee-checkbox-row .custom-checkbox{
    min-width: 210px;
}


.react-confirm-alert .react-confirm-alert-button-group button:has(.move_icon){
    background-color: #0662B9 !important;
    transition: all 0.2s ease-in-out !important;
    border: 1px solid #0662B9 !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.move_icon) img{
    filter: invert(1) brightness(5) !important;
    width: 16px !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.move_icon):hover{
    background-color: #fff !important;
    transition: all 0.2s ease-in-out !important;
    border: 1px solid #0662B9 !important;
    color:  #0662B9 !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.move_icon):hover img{
    filter: none !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.copy_icon){
    background-color: #fff !important;
    border: 1px solid #0662B9 !important;
    color: #0662B9 !important;
    transition: all 0.2s ease-in-out !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.copy_icon) img{
    filter: none !important;
    width: 16px !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.copy_icon):hover{
    background-color: #0662B9 !important;
    color: #fff !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.copy_icon):hover img{
    filter: invert(1) brightness(5) !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.extend_icon){
    background-color: #000 !important;
    border: 1px solid #000 !important;
    transition: all 0.2s ease-in-out !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.extend_icon) img{
    filter: invert(1) brightness(5) !important;
    width: 16px !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.extend_icon):hover{
    background-color: #fff !important;
    color: #000;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.extend_icon):hover img{
    filter: none !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.extend_icon) img{
    filter: invert(1) brightness(5);
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.extend_icon):hover img{
    filter: none;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.delete_icon){
    background-color:#0662B9 !important;
    border: 1px solid#0662B9 !important;
    transition: all 0.2s ease-in-out !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.delete_icon):hover{
    background-color: #fff !important;
    color:#0662B9 !important;
    border: 1px solid#0662B9 !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.cancel_icon){
    background-color: rgb(255, 58, 57) !important;
    border: 1px solid rgb(255, 58, 57) !important;
    transition: all 0.2s ease-in-out !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.cancel_icon) img{
    filter: invert(0) brightness(5) grayscale(1);
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.cancel_icon):hover img{
    filter: none;
}
.react-confirm-alert .react-confirm-alert-button-group button:has(.cancel_icon):hover{
    background-color: #fff !important;
    color: rgb(255, 58, 57) !important;
    border: 1px solid rgb(255, 58, 57) !important;
}
.crew-modal .form-group select.react-datepicker__month-select{
    height: auto !important;
}
.crew-modal .form-group .react-datepicker__header:has(.react-datepicker__month-select) select.react-datepicker__year-select{
    height: auto !important;

}
.export-btns .dropdown-toggle{
    padding: 10px 15px;
    border: 1px solid #4d4d4d;
    background-color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #4d4d4d;
}
.export-btns .dropdown-menu{
    width: auto;
}
.export-btns .dropdown-menu li a{
    padding: 7px 15px !important;
    color: #404040;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.export-btns .dropdown-menu li a img{
    margin-right: 10px;
    filter: grayscale(1) contrast(0.5);
}
.details-listing li.reason-box{
    padding-top: 15px;
    padding: 15px; background: #f5f5f5; border-radius: 10px; border: 1px solid #ddd;
    margin-top: 10px;
}
.report-setting-box>.row{
    padding: 15px 10px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    /* margin-bottom: 15px; */
    align-items: center;
    margin: 0 0 15px;
    border-radius: 15px;
}
/* .report-setting-box>.row>*{
    width: 33.33%;
} */
/* .report-setting-box>.row>*:last-child{
    width: 10%;
} */
.report-setting-box .custom-react-select>span+div{
    height: 50px;
    background-color: #fff;
}
.report-setting-box .custom-react-select>span+div>div{
    max-height: 45px;
    font-size: 14px;
}
.report-setting-box .custom-react-select>span+div>div{
    max-height: 50px;
}
.report-setting-box .custom-react-select input{
    height: 33px;
}
.report-setting-box .custom-react-select input{
    height: 33px;
    font-size: 14px;

}
.employee-code-table .table-bordered>:not(caption)>*>*{   
    border: 1px solid #ddd;
}
.employee-code-table{
    margin-top: 20px;
}
.employee-code-table .table tr th{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
}
.employee-code-table .table tr td{
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.customSelect-wrap.form-group .input-with-label{
    background-color: #fff;
}
.report-setting-box .row .btn.btn-fill.btn-danger{
    background-color: #b00000;
    border-color: #b00000;
    height: 45px;
    font-size: 15px;
}
.filter-new-btn{
    display: flex;
    align-items: center;
}
.filter-new-btn .dropdown-toggle img{
    width: 16px;
    margin-right: 5px;
}
.filter-new-btn .dropdown-toggle:hover img{
    filter: invert(1) brightness(5);
}
.filter-new-btn .dropdown-toggle::after{
    display: none;
}
.filter-new-btn .dropdown-toggle{
    padding: 10px 15px;
    border: 1px solid #4d4d4d;
    background-color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    height: auto;
}
.filter-new-btn .dropdown-menu{
    min-width: 150px;
    padding: 10px 0;
}
.filter-new-btn .dropdown-menu li a{
    padding: 7px 15px !important;
    color: #404040;
    font-size: 15px;
    font-weight: 400;
}
.filter-new-btn .dropdown-menu li a:focus{
    background-color:#000 ;
    color: #fff;
}


/* 13-02-2025 */
.common-modal .modal-dialog{
    max-width: 1100px !important;
}
.custom-form-group .custom-react-select>div>div>div:first-child>label{
    font-size: 14px;
}
.custom-form-group .custom-react-select>span+div>div>div{
    font-size: 14px;
}

/* subcontractor detail */

.request-field .employee-checkbox-row .custom-checkbox {
    min-width: 140px;
  }
  .request-field textarea.form-control {
    height: 100px !important;
    font-size: 14px;
    font-weight: 400;
    color: #212529;
    resize: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #dcdbdd;
    border-radius: 12px;
  }
  .request-field textarea.form-control:focus {
    border-color: #dcdbdd;
  }
  .comman-submit-btn {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #ddd;
  }
  .input-with-label:focus {
    outline: none;
    box-shadow: none;
    border-color: #ddd;
    background-color: transparent;
  }
  .filter-box .form-group .custom-react-select > span + div {
    height: 50px;
    background-color: transparent;
  }
  .input-with-label {
    height: 50px;
    background-color: transparent;
    font-size: 14px;
  }
  .input-with-label .rs-input-group input {
    font-size: 14px;
  }
  .form-check-group .employee-checkbox-row {
    margin-top: 0 !important;
  }
  
  .cannon-modal .modal-header {
    padding: 15px 25px;
  }
  .cannon-modal .modal-header h2 {
    font-size: 27px;
    font-weight: 600;
    color: #000;
  }
  .cannon-modal .modal-header .btn-close {
    opacity: 1;
  }
  .cannon-modal .modal-body {
    padding: 30px 25px;
  }
  .input-with-label::placeholder {
    color: #212529;
  }
  .fields-label-text{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    display: block;
  }
  .fields-label{
    background: #fff;
      padding: 25px 15px 15px;
      border-radius: 15px;
      border: 1px solid #ddd;
  }
  .comman-submit-btn .left-box .btn:not(:last-child){
    margin-right: 10px;
  }
/* subcontractor detail */

@media(max-width:1199px){
    .report-setting-box>.row>*{
        width: 100%;
        margin-bottom: 20px;
    }
    .report-setting-box>.row>*:last-child{
        width: 20%;
    }
}
@media(max-width:991px){
    .cannon-modal .modal-dialog {
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media(max-width:767px){
    .report-setting-box>.row>*:last-child{
        width: 100%;
    }
    .report-setting-box>.row{
        padding: 15px 0;
    }
    .filter-specfic.section-head .right-button .btn.btn-fill:not(:first-child) {
        width: calc(50% - 10px);
        margin-bottom: 10px;
        margin-right: 10px !important;
    }
    .btn.add-backbtn{
        background-color:transparent ;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        filter: invert(1) brightness(0);
    
    }
    .btn.add-backbtn:hover{
        filter: none;
        background-color: transparent;
        border-color: #0662B9;
    }
    .cannon-modal .modal-header h2 {
        font-size: 20px;
      }
      .cannon-modal .modal-header {
        padding: 15px 12px;
      }
      .cannon-modal .modal-body {
        padding: 20px 12px;
      }
}
@media(max-width:576px){
    .filter-new-btn{
        order: 3;
    }
}